import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 0.5rem 1rem;
  font-size: 12px;
  display: inline-block;
  border-radius: 5px;
`;

const color = {
  Marked: { color: "#EF5350", backgroundColor: "#FFCDD2" },
  Complete: { color: "#388E3C", backgroundColor: "#DCEDC8" },
  Processing: { color: "#F9A825", backgroundColor: "#FFF9C4" },
  Hold: { color: "#26C6DA", backgroundColor: "#B2EBF2" },
  none: { color: "#78909C", background: "#ECEFF1" },
};

export default function Chip({ type, text }) {
  return <Wrapper style={{ ...color[type] }}>{text}</Wrapper>;
}
